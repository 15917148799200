import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { HttpService } from '@appCore/services/http/http.service';
import { Urls } from '@appCore/urls/urls';
import { ConsiderationTypes } from '@appMain/calendar-consideration/enums/consideration-types.enum';
import { FileNodeModel } from '@appPersonalCabinet/models/file-node.model';
import { DictionaryEnumModel } from '@models/base/dictionary-enum.model';
import { DictionaryFullNameModel } from '@models/base/dictionary-full-name.model';
import { DictionaryWithDescriptionModel } from '@models/base/dictionary-with-description.model';
import { DictionaryWithIntegerValueModel } from '@models/base/dictionary-with-integer-value.model';
import { DictionaryWithShortNameModel } from '@models/base/dictionary-with-short-name.model';
import { DictionaryModel } from '@models/base/dictionary.model';
import { DictionaryWithCodeModel } from '@models/base/object-with-code.model';
import { OrganizationModel } from '@models/base/organization.model';
import { ConsiderationParticipantTypeModel } from '@models/calendar-consideration/consideration-participant-type.model';
import { GroupedProjectTypeModel } from '@models/document-package/project-type.model';
import { DocumentStatusItemModel } from '@models/document-statuses/document-status-item.model';
import { DocumentStatusValueModel } from '@models/document-statuses/document-status-value.model';
import { DocumentTypeModel } from '@models/document/document-type.model';
import { TemplateDocumentTypeModel } from '@models/document/template-document-type.model';
import { EmployeeBaseModel } from '@models/employee/employee-base.model';
import { ProjectGroupType } from '@models/enums/project-group-type.enum';
import { NomenclatureModel } from '@models/nomenclatures/nomenclature.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DictionaryApiService {
  constructor(private http: HttpService) {}

  public getPackageTypes(): Observable<DictionaryModel[]> {
    const url = Urls.packageTypes();
    return this.http.getObservable(url);
  }

  /** Получение списка этапов */
  public getAgreementRoutePhases(): Observable<DictionaryModel[]> {
    const url = Urls.agreementRoutePhases();
    return this.http.getObservable(url);
  }

  /** Получение списка типов проектов */
  public getProjectTypes(): Observable<GroupedProjectTypeModel[]> {
    const url: string = Urls.documentsPackageProjectTypes();

    return this.http.getObservable(url);
  }

  /** Получение всех типов проекта доступных пользователю по роли */
  public getProjectTypesAvailable(): Observable<GroupedProjectTypeModel[]> {
    const url = Urls.getProjectTypesAvailable();
    return this.http.getObservable(url);
  }

  /** Получение списка статусов */
  public getStatuses(): Observable<DictionaryModel[]> {
    const url: string = Urls.documentsPackageStatuses();

    return this.http.getObservable(url);
  }

  /** Получение списка типов заседаний(форм утверждения) */
  public getMeetingTypes(): Observable<DictionaryFullNameModel[]> {
    const url: string = Urls.documentsPackageMeetingTypes();

    return this.http.getObservable(url);
  }

  /** Получение списка типов рассмотрения */
  public getConsiderationTypes(): Observable<DictionaryModel[]> {
    const url: string = Urls.documentsPackageConsiderationTypes();

    return this.http.getObservable(url);
  }

  /** Получение списка организаций по фильтру
   * name - фильтр по организации
   * withEmpty - с пустыми организациями (организации без сотрудников) */
  public getOrganizations(name: string, withEmpty?: boolean): Observable<OrganizationModel[]> {
    const url: string = Urls.getOrganizations();
    let params = new HttpParams();
    params = params.append('name', name);
    params = params.append('withEmpty', String(withEmpty ? withEmpty : false));
    return this.http.getObservable(url, { params });
  }

  /** Получение списка всех организаций */
  public getOrganizationsAll(): Observable<DictionaryWithShortNameModel[]> {
    const url: string = Urls.getOrganizationsAll();

    return this.http.getObservable(url);
  }

  /** Получение списка всех организаций для текущего пользователя */
  public getOrganizationsForCurrentUser(): Observable<DictionaryWithShortNameModel[]> {
    const url: string = Urls.getOrganizationsForCurrentUser();

    return this.http.getObservable(url);
  }

  /** Получение организации по идентификатору */
  public getOrganizationById(id: number): Observable<DictionaryWithShortNameModel> {
    const url: string = Urls.getOrganizationById(id);
    return this.http.getObservable(url);
  }

  /** Получает список активных организаций в виде древа по id организации текущего пользователя */
  public getActiveTreeOrganizations(organizationId: number): Observable<FileNodeModel> {
    const url: string = Urls.getActiveOrganizationTree(organizationId);
    return this.http.getObservable(url);
  }

  /** Получает список организаций согласно фильтру и субфильтру Вынести решение */
  public getOrganizationsByDecisionFilter(paramsData: Params): Observable<DictionaryWithShortNameModel[]> {
    const url: string = Urls.getOrganizationsByDecisionFilterRoute();

    const params = this.http.getRequestParams(paramsData, false);

    return this.http.getObservable(url, { params });
  }

  /**
   * Получает список типов документов в завсисимости от группы проекта
   *
   * @param projectGroupTypeId - id группы проекта документов (ПМ/ППМ | ЛПА)
   */
  public getDocumentTypes(projectGroupTypeId: number = 1): Observable<DocumentTypeModel[]> {
    const url: string = Urls.getDocumentTypesUrl();
    let params = new HttpParams();
    params = params.append('projectGroupTypeId', projectGroupTypeId.toString());
    return this.http.getObservable(url, { params });
  }

  /**
   * Получает список типов документов в завсисимости от группы проекта
   *
   * @param projectGroupTypeId - id группы проекта документов (ПМ/ППМ | ЛПА)
   */
  public getDocumentTypesAvailable(
    documentPackageId: number,
    projectGroupTypeId: ProjectGroupType = ProjectGroupType.LEGAL_ACTS
  ): Observable<DocumentTypeModel[]> {
    const url: string = Urls.getDocumentTypesAvailableUrl(documentPackageId);
    let params = new HttpParams();
    params = params.append('projectGroupTypeId', projectGroupTypeId.toString());

    return this.http.getObservable(url, { params });
  }

  /** Полученить типы документов для сущности Пакет повестки */
  public getCalendarConsiderationDocumentTypes(agendaPackageTypeId: number): Observable<DocumentTypeModel[]> {
    const url: string = Urls.getCalendarConsiderationDocumentTypes();
    return this.http.getObservable(`${url}/${agendaPackageTypeId}`);
  }

  /** Получение списка должностей */
  public getEmployeePositions(name: string, positionName = ''): Observable<DictionaryModel[]> {
    const url: string = Urls.getEmployeePositions(name, positionName);

    return this.http.getObservable(url);
  }

  /** Получение сотрудников организации с указанной ролью ОИБ */
  public getEmployeesByRoleAndOrganizationUsingGET(name: string, orgId: number): Observable<EmployeeBaseModel[]> {
    const url: string = Urls.getEmployeesByRoleAndOrganizationUsingGET(name, orgId);

    return this.http.getObservable(url);
  }

  /** Получения всех типов резолюций задач в Журнале Задач */
  getTaskResolutions(): Observable<DictionaryModel[]> {
    const url = Urls.getTaskResolutions();

    return this.http.getObservable(url);
  }

  /** Получение списка типов пакета повестки */
  public getAllAgendaPackageTypes(): Observable<DictionaryModel[]> {
    const url = Urls.getAllAgendaPackageTypes();

    return this.http.getObservable(url);
  }

  /** Получение списка доступных типов пакета повестки */
  public getAvailableAgendaPackageTypes(): Observable<DictionaryModel[]> {
    const url = Urls.getAllAgendaPackageTypes();

    return this.http.getObservable(url, { params: { onlyAvailable: true } });
  }

  /** Получение списка типов вопросов пакета повестки */
  public getAgendaPackageQuestionsTypes(): Observable<DictionaryModel[]> {
    const url = Urls.getAgendaPackageQuestionsTypes();

    return this.http.getObservable(url);
  }

  /** Получение списка типов вопросов пакета повестки для типа Заседание */
  public getAgendaPackagePMMeetingQuestionsTypes(): Observable<DictionaryModel[]> {
    const url = Urls.getAgendaPackagePMMeetingQuestionsTypes();

    return this.http.getObservable(url, {
      /** В параметры передаем айдишник типа ПП Заседание */
      params: { agendaPackageTypeId: ConsiderationTypes.meetingPM },
    });
  }

  /** Получение списка типов повторений */
  public getRepeatTypes(): Observable<DictionaryWithIntegerValueModel[]> {
    const url = Urls.getRepeatTypes();

    return this.http.getObservable(url);
  }

  /** Получение Шаблонов Документа */
  public getTemplateDocumentTypes(
    config: { projectTypeId?: number; docTypeId?: number } = {}
  ): Observable<TemplateDocumentTypeModel[]> {
    const url = Urls.getTemplateDocumentTypes();
    return this.http.getObservable(url, { params: config });
  }

  /** Получение типов участников пакета повестки */
  public getConsiderationParticipantTypes(): Observable<ConsiderationParticipantTypeModel[]> {
    const url = Urls.getConsiderationParticipantTypes();
    return this.http.getObservable(url);
  }

  /** Получение типов решений по вопросам пакета повестки */
  public getConsiderationQuestionResolutionTypes(agendaPackageTypeId: number): Observable<DictionaryModel[]> {
    const url = Urls.getConsiderationQuestionResolutionTypes();

    return this.http.getObservable(url, {
      params: { agendaPackageTypeId },
    });
  }

  public getConsiderationQuestionCategories(): Observable<DictionaryWithCodeModel[]> {
    const url = Urls.getQuestionCategoriesTypes();
    return this.http.getObservable(url);
  }

  public getSignerCategories(projectGroupTypeId: number): Observable<DictionaryEnumModel[]> {
    const url = Urls.getSignerCategories();
    const params = {
      projectGroupTypeId,
    };
    return this.http.getObservable(url, { params });
  }

  /** Получить все возможные значения статусов ПД */
  public getDocumentPackageStatusValues(): Observable<DocumentStatusValueModel[]> {
    const url = Urls.getDocumentStatusValues();
    return this.http.getObservable(url);
  }

  /** Получить все возможные cтатусы ПД */
  public getDocumentPackageStatusItems(): Observable<DocumentStatusItemModel[]> {
    const url = Urls.getDocumentStatusItems();
    return this.http.getObservable(url);
  }

  public getDocumentPackageRelationTypes(): Observable<DictionaryModel[]> {
    const url = Urls.getDocumentPackageRelationTypes();

    return this.http.getObservable(url);
  }

  public getDocumentStages(): Observable<DictionaryWithDescriptionModel[]> {
    const url = Urls.getDocumentStages();

    return this.http.getObservable(url);
  }

  public getCaseStatuses(): Observable<DictionaryModel[]> {
    const url = Urls.getCaseStatuses();

    return this.http.getObservable(url);
  }

  public getOrganizationsLeader(): Observable<DictionaryModel[]> {
    const url = Urls.getOrganizationsLeader();

    return this.http.getObservable(url);
  }

  /** Получение руководителя этапа (только ПУ ПМ и ОАУ) */
  public getOrganizationsLeaderByPhaseType(phaseId: number): Observable<EmployeeBaseModel> {
    const url = Urls.getOrganizationsLeadersByPhaseType(phaseId);

    return this.http.getObservable(url);
  }

  public getAlertPeriodTypes(): Observable<DictionaryWithDescriptionModel[]> {
    const url = Urls.getAlertPeriodTypes();

    return this.http.getObservable(url);
  }

  /** Получение списка 10 самых используемых мест проведения пакета повестки */
  public getTopTenAgendaPackagePlaces(): Observable<DictionaryModel[]> {
    const url = Urls.getTopTenAgendaPackagePlaces();

    return this.http.getObservable(url);
  }

  /** Получение словаря типов шаблона сопроводительного текста */
  public getCoveringTextTypes(): Observable<DictionaryModel[]> {
    const url = Urls.getTemplateCoveringTextTypes();

    return this.http.getObservable(url);
  }

  /** Получение словаря типов поруче6ния для исполнителя */
  public getAssignmentTypes(): Observable<DictionaryWithCodeModel[]> {
    const url = Urls.getAssignmentTypes();

    return this.http.getObservable(url);
  }

  /** Получение словаря статусов резервирования номера документа */
  public getReservationStatuses(): Observable<DictionaryModel[]> {
    const url = Urls.getReservationStatuses();

    return this.http.getObservable(url);
  }

  /** Получение словаря ограничений для документов */
  public getDocumentProhibitionTypes(): Observable<DictionaryWithCodeModel[]> {
    const url = Urls.getDocumentProhibitionTypes();

    return this.http.getObservable(url);
  }

  public getFolderViewAccessTypes(): Observable<DictionaryModel[]> {
    const url = Urls.getFolderViewAccessTypes();

    return this.http.getObservable(url);
  }

  public getFolderEditRightTypes(): Observable<DictionaryModel[]> {
    const url = Urls.getFolderEditRightTypes();

    return this.http.getObservable(url);
  }

  public getDelegationPositions(onlyActive: boolean): Observable<OrganizationModel[]> {
    const url = Urls.getDelegationPositions();
    let params = new HttpParams();
    params = params.append('onlyActive', onlyActive);
    return this.http.getObservable(url, { params });
  }

  public getImportFormatsByDocumentTypeId(documentTypeId: number): Observable<string> {
    const url = Urls.getImportFormatsByDocumentTypeId();

    return this.http.getObservable(url, {
      responseType: 'text',
      params: { documentTypeId },
    });
  }

  public getNomenclatureIndexes(): Observable<NomenclatureModel[]> {
    const url = Urls.getNomenclatures();
    return this.http.getObservable(url);
  }

  public getAvailableNomenclatureIndexes(): Observable<NomenclatureModel[]> {
    const url = Urls.getAvailableNomenclatures();
    return this.http.getObservable(url);
  }
}
